import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import "./main.sass"; // Tell webpack to import favicon.ico

import "core-js/stable";
import "regenerator-runtime/runtime";

import { IntlProvider } from "react-intl";
import { getLanguage } from "./libs/language";

// Internationalisation
import fr from "./langs/fr.json";

import App from "./App";

require("./favicon.png");

const language = getLanguage() || "fr";

const theme = {
  white: "#FFF",
  blue: "#010023",
  raspberry: "#ff4545",
};

ReactDOM.render(
  <IntlProvider locale={language} messages={fr}>
    <ThemeProvider theme={theme}>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </IntlProvider>,
  document.getElementById("root")
);
