import styled from "styled-components";

import * as C from "../commons/styles";

export const Wrapper = styled.div`
  margin: 55px 20px;
`;

export const Content = styled(C.Content)`
  flex-direction: column;

  h1 {
    padding: 0 0 20px 40px;
    font: 400 28px/41px "Permanent Marker";
  }

  @media (max-width: 768px) {
    h1 {
      margin: 0;
      padding: 0 0 20px 0;
    }
  }
`;

export const Expertises = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0 20px;
  }
`;

export const Expertise = styled.article`
  flex: 1;
  max-width: 28%;
  h2 {
    margin: 0 0 30px 0;
    font: 700 24px/36px "Poppins";
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 30px;

    h2 {
      margin: 0;
    }
  }
`;

export const Text = styled.div`
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;

    strong {
      font-weight: 700;
    }
  }

  p + p {
    margin-top: 25px;
  }
`;
