import styled from "styled-components";

import * as C from "../commons/styles";

export const Logo = styled.div`
  margin: 0 auto;
  a {
    display: block;
  }
  svg {
    width: 150px;
    height: auto;

    transition: width 0.2s ease-in;
  }

  @media (max-width: 768px) {
    svg {
      width: 75px;
    }
  }
`;

export const Wrapper = styled.header`
  padding: 0 20px 500x 20px;
  z-index: 2;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    margin: 0;
    padding: 15px 20px 5px 20px;
    width: 100%;
    background: ${({ theme }) => theme.raspberry};

    &.sticky ${Logo} svg {
      width: 50px;
    }
  }
`;

export const Content = styled(C.Content)`
  align-items: center;
`;
