import React from "react";
import PropTypes from "prop-types";

import Header from "../Header";
import Footer from "../Footer";

import * as S from "./styles";

const Layout = ({ className, children }) => (
  <S.Wrapper className={className}>
    <Header />
    <S.Main>
      {children}
    </S.Main>
    <Footer />
  </S.Wrapper>
);

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Layout;
