import styled from "styled-components";

import * as C from "../commons/styles";

export const Wrapper = styled.div``;

export const Content = styled(C.Content)`
  align-items: center;

  @media (max-width: 768px) {
    margin: 0 20px;
  }

  img {
    width: 220px;
    margin: 0 150px 0 54px;
    transform: rotate(-4.66deg);

    @media (max-width: 768px) {
      margin: 0 auto 30px auto;
    }
  }

  > div {
    a {
      color: inherit;
      &:hover {
        text-decoration: none;
      }
    }

    h1 {
      font: 700 24px/36px "Poppins";
    }

    p {
      font: 700 16px/24px "Poppins";
    }
  }
`;
