import React from "react";
import { useIntl } from "react-intl";

import * as S from "./styles";

const Expertises = () => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <S.Content>
        <h1>{intl.formatMessage({ id: "expertises.title" })}</h1>

        <S.Expertises>
          {["curation", "podcast", "brand"].map((exp) => (
            <S.Expertise key={exp}>
              <h2>{intl.formatMessage({ id: `expertises.${exp}.title` })}</h2>
              <S.Text>
                {intl.formatMessage(
                  { id: `expertises.${exp}.content` },
                  {
                    br: <br />,
                    p: (txt) => <p>{txt}</p>,
                    strong: (txt) => <strong>{txt}</strong>,
                  }
                )}
              </S.Text>
            </S.Expertise>
          ))}
        </S.Expertises>
      </S.Content>
    </S.Wrapper>
  );
};

export default Expertises;
