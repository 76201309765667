import React from "react";
import { useIntl } from "react-intl";

import * as S from "./styles";

const List = [
  {
    title: "Un podcast pour raconter les dessous d’un projet de design",
    url: "https://clementinechambon.com/shelter",
  },
  {
    title: "Un article très cool sur le design gonflable",
    url: "https://www.punktional.com/saga/design-gonflable",
  },
  {
    title: "Une étude de tendances et de prédictions pour 2022",
    url: "https://www.punktional.com/objets-de-convoitise",
  },
  {
    title: "Une curation de jeunes designers",
    url: "https://www.punktional.com/shop",
  },
];

const Links = () => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <S.Content>
        <h1>{intl.formatMessage({ id: "links.title" })}</h1>
        <S.Links>
          {List.map((link, i) => (
            <S.Link key={`link-${i}`}>
              <a href={link.url} rel="nofollow noreferrer" target="_blank">
                {link.title}
              </a>
            </S.Link>
          ))}
        </S.Links>
      </S.Content>
    </S.Wrapper>
  );
};

export default Links;
