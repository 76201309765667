import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";

import Layout from "../../components/Layout";

import Intro from "../../components/Intro";
import Expertises from "../../components/Expertises";
import Difference from "../../components/Difference";
import Next from "../../components/Next";
import Links from "../../components/Links";
import Visuals from "../../components/Visuals";

import * as S from "./styles";

const Home = () => {
  const intl = useIntl();

  return (
    <Layout subFooter={false}>
      <Helmet>
        <title>{intl.formatMessage({ id: "meta.title" })}</title>
      </Helmet>
      <S.Wrapper>
        <Intro />
        <Expertises />
        <Difference />
        <Next />
        <Links />
        <Visuals />
      </S.Wrapper>
    </Layout>
  );
};

export default Home;
