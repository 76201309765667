import React, { useContext } from "react";
import { ThemeContext } from "styled-components";

const Logo = ({ color }) => {
  const theme = useContext(ThemeContext);
  const fillColor = color || theme.white;

  return (
    <svg
      width="300"
      height="300"
      viewBox="0 0 300 300"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill={fillColor}
          d="M112.9,93.8c3.1,2,5.6,4.8,7.3,8.5c1.8,3.7,2.7,8,2.7,13c0,5-0.9,9.3-2.7,13c-1.8,3.7-4.2,6.5-7.3,8.5
			c-3.1,2-6.6,2.9-10.6,2.9c-3.7,0-6.8-0.8-9.4-2.5s-4.5-3.9-5.7-6.8v31.4H72.6V91.5h14.6v8.6c1.2-2.8,3.1-5.1,5.7-6.8
			c2.6-1.7,5.8-2.5,9.4-2.5C106.3,90.9,109.8,91.9,112.9,93.8z M90.1,106.8c-2,2.1-2.9,4.9-2.9,8.5c0,3.5,1,6.3,2.9,8.5
			s4.5,3.2,7.6,3.2c3.1,0,5.7-1,7.6-3.1c1.9-2.1,2.9-4.9,2.9-8.6s-0.9-6.5-2.9-8.6s-4.4-3.1-7.6-3.1C94.5,103.7,92,104.8,90.1,106.8
			z"
        />
        <path
          fill={fillColor}
          d="M178,91.5v47.7h-14.7v-8.6c-1.4,2.8-3.4,5-6.1,6.7c-2.7,1.6-5.8,2.5-9.4,2.5c-5.5,0-9.8-1.8-13-5.5
			c-3.2-3.6-4.8-8.7-4.8-15V91.5h14.5v25.9c0,3.2,0.8,5.8,2.5,7.6c1.7,1.8,3.9,2.7,6.8,2.7c3,0,5.3-0.9,7-2.8c1.7-1.9,2.6-4.6,2.6-8
			V91.5C163.3,91.5,178,91.5,178,91.5z"
        />
        <path
          fill={fillColor}
          d="M231.3,96.4c3.2,3.7,4.8,8.7,4.8,15v27.7h-14.6v-26c0-3.2-0.8-5.7-2.5-7.5c-1.7-1.8-3.9-2.7-6.7-2.7
			c-3,0-5.3,0.9-7.1,2.8s-2.6,4.6-2.6,8v25.3H188V91.5h14.6v8.7c1.4-2.8,3.4-5.1,6.1-6.8c2.7-1.7,5.9-2.5,9.5-2.5
			C223.8,91,228.1,92.8,231.3,96.4z"
        />
        <path
          fill={fillColor}
          d="M274.7,139.2l-14.3-20.2v20.2h-14.6V75.9h14.6v34.7l14.3-19.1h17.2l-19.2,23.8l19.7,23.8H274.7z"
        />
      </g>
      <g>
        <path
          fill={fillColor}
          d="M18.1,220.7c-2.9-1.2-5.2-3-6.9-5.2c-1.7-2.2-2.6-4.8-2.8-7.7h8.2c0.2,2.4,1.3,4.3,3.3,5.8
			c2,1.5,4.6,2.3,7.9,2.3c3,0,5.4-0.7,7.1-2c1.7-1.3,2.6-3,2.6-5c0-2.1-0.9-3.6-2.8-4.7c-1.9-1-4.7-2-8.6-3
			c-3.5-0.9-6.4-1.9-8.7-2.8c-2.2-1-4.1-2.4-5.7-4.3c-1.6-1.9-2.4-4.4-2.4-7.4c0-2.4,0.7-4.7,2.2-6.7c1.4-2,3.5-3.6,6.2-4.8
			c2.7-1.2,5.7-1.8,9.1-1.8c5.3,0,9.5,1.3,12.8,4c3.2,2.7,5,6.3,5.2,11H37c-0.2-2.5-1.2-4.5-3-6c-1.8-1.5-4.3-2.3-7.4-2.3
			c-2.8,0-5.1,0.6-6.8,1.8c-1.7,1.2-2.5,2.8-2.5,4.8c0,1.6,0.5,2.9,1.5,3.9c1,1,2.3,1.8,3.8,2.4c1.5,0.6,3.7,1.3,6.4,2
			c3.4,0.9,6.2,1.8,8.4,2.7c2.1,0.9,4,2.2,5.5,4c1.5,1.8,2.3,4.1,2.4,7c0,2.6-0.7,5-2.2,7c-1.5,2.1-3.5,3.7-6.1,4.9
			c-2.6,1.2-5.7,1.8-9.1,1.8C24.3,222.6,21,221.9,18.1,220.7z"
        />
        <path
          fill={fillColor}
          d="M82.2,209.2v12.6h-7.6c-5.4,0-9.6-1.3-12.6-4c-3-2.6-4.5-6.9-4.5-12.9v-19.3h-5.9v-12.4h5.9v-11.8h14.9v11.8
			h9.7v12.4h-9.7v19.5c0,1.5,0.3,2.5,1,3.1c0.7,0.6,1.9,1,3.5,1H82.2z"
        />
        <path
          fill={fillColor}
          d="M134.1,174.1v47.7h-7.9v-7c-1.5,2.4-3.6,4.3-6.3,5.7c-2.7,1.4-5.7,2-8.9,2c-3.7,0-7-0.8-10-2.3
			c-3-1.5-5.3-3.8-7-6.9c-1.7-3.1-2.6-6.8-2.6-11.2v-27.9h7.8V201c0,4.7,1.2,8.3,3.6,10.8c2.4,2.5,5.6,3.8,9.7,3.8
			c4.2,0,7.6-1.3,10-3.9c2.4-2.6,3.7-6.4,3.7-11.4v-26.2H134.1z"
        />
        <path
          fill={fillColor}
          d="M147.5,184.9c2-3.7,4.7-6.5,8.1-8.6c3.4-2,7.3-3,11.6-3c3.7,0,7.2,0.9,10.4,2.6c3.2,1.7,5.6,4,7.3,6.7v-25.2
			h8v64.4h-8v-9c-1.6,2.8-3.9,5.2-7,7c-3.1,1.8-6.7,2.7-10.8,2.7c-4.2,0-8.1-1-11.5-3.1c-3.5-2.1-6.2-5-8.1-8.8c-2-3.8-3-8.1-3-12.9
			C144.5,192.9,145.5,188.6,147.5,184.9z M182.7,188.5c-1.5-2.7-3.4-4.7-5.9-6.1c-2.5-1.4-5.2-2.1-8.1-2.1s-5.7,0.7-8.1,2.1
			c-2.4,1.4-4.4,3.4-5.8,6.1c-1.5,2.7-2.2,5.8-2.2,9.4c0,3.7,0.7,6.8,2.2,9.5c1.4,2.7,3.4,4.8,5.8,6.2c2.4,1.4,5.1,2.1,8.1,2.1
			s5.7-0.7,8.1-2.1c2.5-1.4,4.4-3.5,5.9-6.2c1.4-2.7,2.2-5.8,2.2-9.4C184.9,194.3,184.1,191.1,182.7,188.5z"
        />
        <path
          fill={fillColor}
          d="M206.5,164.8c-1-1-1.6-2.3-1.6-3.8c0-1.5,0.5-2.8,1.6-3.8s2.3-1.6,3.8-1.6c1.4,0,2.7,0.5,3.7,1.6
			c1,1,1.5,2.3,1.5,3.8c0,1.5-0.5,2.8-1.5,3.8c-1,1-2.2,1.6-3.7,1.6C208.9,166.4,207.6,165.8,206.5,164.8z M214.2,174.1v47.7h-7.9
			v-47.7H214.2z"
        />
        <path
          fill={fillColor}
          d="M236.3,219.5c-3.6-2-6.5-4.9-8.5-8.7c-2.1-3.7-3.1-8.1-3.1-13c0-4.9,1.1-9.2,3.2-12.9s5-6.6,8.7-8.6
			c3.7-2,7.7-3,12.3-3c4.5,0,8.6,1,12.3,3c3.7,2,6.5,4.9,8.7,8.6c2.1,3.7,3.2,8,3.2,13c0,4.9-1.1,9.3-3.3,13
			c-2.2,3.7-5.1,6.6-8.8,8.7c-3.7,2-7.8,3-12.4,3C244,222.6,240,221.5,236.3,219.5z M256.5,213.6c2.5-1.3,4.5-3.3,6-6
			c1.5-2.7,2.3-5.9,2.3-9.7s-0.8-7.1-2.3-9.7c-1.5-2.7-3.5-4.7-5.9-6c-2.4-1.3-5.1-2-7.9-2c-2.9,0-5.6,0.7-8,2
			c-2.4,1.3-4.3,3.3-5.8,6c-1.5,2.7-2.2,5.9-2.2,9.7c0,3.9,0.7,7.2,2.1,9.8c1.4,2.7,3.3,4.7,5.7,6c2.4,1.3,5,2,7.8,2
			C251.3,215.6,254,214.9,256.5,213.6z"
        />
      </g>
    </svg>
  );
};

export default Logo;
