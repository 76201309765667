import styled from "styled-components";

import * as C from "../commons/styles";

export const Wrapper = styled.div`
  margin: 100px 0;

  @media (max-width: 768px) {
    margin: 0 20px 80px 20px;
  }
`;

export const Content = styled(C.Content)`
  align-items: center;
  h1 {
    flex: 1;
    margin: 0 0 0 40px;
    font: 400 28px/41px "Permanent Marker";
  }

  p {
    flex: 1;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
  }

  @media (max-width: 768px) {
    h1 {
      margin: 0;
    }
  }
`;
