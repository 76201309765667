import styled from "styled-components";

import * as C from "../commons/styles";

export const Wrapper = styled.footer`
  padding: 255px 0 5px 0;
  background: ${({ theme }) => theme.raspberry};

  @media (max-width: 768px) {
    padding-top: 50px;
  }
`;

export const Content = styled(C.Content)`
  flex-direction: column;
`;

export const Social = styled.ul`
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 30px 45px;

  li {
    margin: 0 10px;
    a {
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: ${({ theme }) => theme.white};
        }
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  line-height: 30px;
  ul {
    display: flex;
  }
  li {
    margin: 0 30px;
    a {
      display: inline-block;
      color: ${({ theme }) => theme.white};
      text-decoration: none;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  @media (max-width: 768px) {
    align-items: flex-start;
    font-size: 12px;
    line-height: 18px;
    ul {
      flex-direction: column;
    }
  }
`;

export const Legals = styled.p`
  margin: 30px 0 10px 0;
  display: flex;
  align-self: center;
  opacity: 0.5;
  color: ${({ theme }) => theme.white};

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;
