import React from "react";
import { useIntl } from "react-intl";

import * as S from "./styles";

const Intro = () => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <S.Content>
        <S.Image>
          <img src="/images/story.jpg" alt="Story" />
        </S.Image>
        <S.Text>
          {intl.formatMessage(
            { id: "intro.content" },
            {
              br: <br />,
              p: (txt) => <p>{txt}</p>,
              strong: (txt) => <strong>{txt}</strong>,
            }
          )}
        </S.Text>
      </S.Content>
    </S.Wrapper>
  );
};

export default Intro;
