import styled from "styled-components";

import * as C from "../commons/styles";

export const Wrapper = styled.div`
  margin: 240px 0 0 0;

  @media (max-width: 768px) {
    margin: 80px 20px 0 20px;
  }
`;

export const Content = styled(C.Content)`
  align-items: center;
  justify-content: space-between;
`;

export const Visual = styled.div`
  flex: 1;
  max-width: 30%;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
  }
`;
