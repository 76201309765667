import styled from "styled-components";

export const Wrapper = styled.main`
  min-height: 100vh;

  @media (max-width: 768px) {
    padding-top: 80px;
  }
`;

export const Main = styled.section`
  min-height: calc(100vh - 156px - 225px);
`;

export const Content = styled.div`
  position: relative;
  grid-area: newsletter;
  display: flex;
  justify-content: center;
  margin: 84px 0 0 0;
  background: ${({ theme }) => theme.kingBlue || "#002fa7"};

  @media (max-width: 768px) {
    margin: 0;
  }
`;
