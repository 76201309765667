import React from "react";
import { useIntl } from "react-intl";

import * as S from "./styles";

const Difference = () => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <S.Content>
        <h1>{intl.formatMessage({ id: "difference.title" })}</h1>
        <p>
          {intl.formatMessage(
            { id: "difference.content" },
            {
              br: <br />,
            }
          )}
        </p>
      </S.Content>
    </S.Wrapper>
  );
};

export default Difference;
