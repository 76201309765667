import React from "react";
import { useIntl } from "react-intl";

import * as S from "./styles";

const Next = () => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      <S.Content>
        <img src="/images/contact.jpg" alt="" />
        <div>
          <h1>
            {intl.formatMessage(
              { id: "next.title" },
              {
                a: (chunk) => <a href="mailto:hello@punktional.com">{chunk}</a>,
              }
            )}
          </h1>
          <p>
            {intl.formatMessage(
              { id: "next.links" },
              {
                insta: (chunk) => (
                  <a
                    href="https://www.instagram.com/punktional_/"
                    rel="nofollow noreferrer"
                    target="_blank"
                  >
                    {chunk}
                  </a>
                ),
                linkedin: (chunk) => (
                  <a
                    href="https://www.linkedin.com/company/punktional"
                    rel="nofollow noreferrer"
                    target="_blank"
                  >
                    {chunk}
                  </a>
                ),
              }
            )}
          </p>
        </div>
      </S.Content>
    </S.Wrapper>
  );
};

export default Next;
