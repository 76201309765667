import styled from "styled-components";

import * as C from "../commons/styles";

export const Wrapper = styled.div`
  margin-top: 90px;
`;

export const Content = styled(C.Content)`
  flex-direction: column;
  align-items: center;

  h1 {
    font: 400 28px/41px "Permanent Marker";
    text-align: center;
  }
`;

export const Links = styled.ul``;

export const Link = styled.li`
  display: block;
  text-align: center;
  font: 500 16px/24px "Poppins";
  a {
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
`;
