import React from "react";

import * as S from "./styles";

const Visuals = () => (
  <S.Wrapper>
    <S.Content>
      {[...Array(3)].map((_, i) => (
        <S.Visual>
          <img src={`/images/visual_${i + 1}.jpg`} alt="" />
        </S.Visual>
      ))}
    </S.Content>
  </S.Wrapper>
);

export default Visuals;
