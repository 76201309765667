import React, { useEffect} from "react";
import { Route, useLocation, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import AppContext from "./AppContext";

import ScrollToTop from "./components/ScrollToTop";
import * as Pages from "./pages/index";

const ROUTES = [
  { path: "/", exact: true, component: Pages.Home, open: true },
  { path: "*", component: Pages.NotFound },
];

const App = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.GA_ID) ReactGA.initialize(process.env.GA_ID);

    if (process.env.SENTRY_ID) {
      Sentry.init({
        dsn: process.env.SENTRY_ID,
        integrations: [new Integrations.BrowserTracing()],
        environment: pprocess.env.ENV || "development",

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
    }
  }, []);

  useEffect(() => {
    if (process.env.GA_ID) {
      ReactGA.pageview(location.pathname);
    }
  }, [location]);

  return (
    <AppContext.Provider>
      <ScrollToTop />
      <Switch>
        {ROUTES.filter((elt) => elt.open).map(
          ({ path, exact, component: Comp }) => (
            <Route
              path={path}
              exact={exact}
              strict={false}
              sensitive={false}
              key={path}
              render={() => <Comp />}
            />
          )
        )}
      </Switch>
    </AppContext.Provider>
  );
};

export default App;
