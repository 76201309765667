import styled from "styled-components";

import * as C from "../commons/styles";

export const Wrapper = styled.div`
  margin: 100px 40px 0 40px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const Content = styled(C.Content)`
  background: ${({ theme }) => theme.white};
  border: 1px solid #f2f2f2;
`;

export const Image = styled.div`
  flex: 1;
  position: relative;
  width: 50%;

  img {
    display: block;
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Text = styled.div`
  flex: 1;
  padding: 60px 60px 0 33px;
  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    strong {
      font-weight: 700;
    }
  }

  p + p {
    margin-top: 25px;
  }

  @media (max-width: 768px) {
    padding: 30px;
    font-size: 16px;
    line-height: 18px;
  }
`;
